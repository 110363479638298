import React, { FunctionComponent } from "react";
import ComponentsMapAsync from "../ComponentsMapAsync";
import componentsMapSync from "../ComponentsMapSync";
export interface BlockRendererInterface {
  section: {
    __typename?: string;
    _type?: string;
  };
  index: number;
  data?: any;
}

const BlockRenderer: FunctionComponent<BlockRendererInterface> = ({ section, index, data }) => {
  if (!section) {
    return null;
  }
  const sanityType = (section.__typename ? section.__typename : section._type) as string;
  const componentProps = {
    ...section,
    index,
    data,
    preferPerformance: index < 1
  }

  return (
    <ComponentsMapAsync type={sanityType} {...componentProps} />
  )
};

export default BlockRenderer;
