import React, { FunctionComponent } from "react";
import loadable from "@loadable/component";

const ArticleStacker = loadable(() => import("../ArticleStacker"));
const ProductStacker = loadable(() => import("../ProductStacker"));
const HeroBanner = loadable(() => import("../HeroBanner"));
const ProductSliderV2 = loadable(() => import("../ProductSliderV2"));
const HeroBannerSlider = loadable(() => import("../HeroBannerSlider"));
const ArticleSlider = loadable(() => import("../ArticleSlider"));
const CollectionSlider = loadable(() => import("../CollectionSlider"));
const TiktokSlider = loadable(() => import("../TiktokSlider"));
const ProductFeaturesSlider = loadable(() => import("../ProductFeaturesSlider"));
const SearchListing = loadable(() => import("../SearchListing"));
const MasonryImageBlock = loadable(() => import("../MosonryImageBlock"));
const SimpleText = loadable(() => import("../SimpleText"));
const PersonListing = loadable(() => import("../PersonListing"));
const SimpleImageBanner = loadable(() => import("../SimpleImageBanner"));
const SimpleVideoBanner = loadable(() => import("../SimpleVideoBanner"));
const ImageVideoBlock = loadable(() => import("../ImageVideoBlock"));
const StepListing = loadable(() => import("../StepListing"));
const QRCode = loadable(() => import("../QRCode"));

import Fallback from "../FallbackLoader";

type BlockRenderableSanityTypeNameToComponentMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [typeName: string]: FunctionComponent<any>;
};

const componentsMap: BlockRenderableSanityTypeNameToComponentMap = {
  SanityArticleStacker: ArticleStacker,
  SanityProductStacker: ProductStacker,
  SanityHeroBanner: HeroBanner,
  SanityHeroBannerSlider: HeroBannerSlider,
  SanityProductSliderV2: ProductSliderV2,
  SanityArticleSlider: ArticleSlider,
  SanityCollectionSlider: CollectionSlider,
  SanityTiktokSlider: TiktokSlider,
  SanityProductFeaturesBlock: ProductFeaturesSlider,
  SanitySearchListingBlock: SearchListing,
  SanityMasonryimageBlock: MasonryImageBlock,
  SanitySimpleText: SimpleText,
  SanityPersonListing: PersonListing,
  SanitySimpleImageBanner: SimpleImageBanner,
  SanitySimpleVideoBanner: SimpleVideoBanner,
  SanityImageVideoBlock: ImageVideoBlock,
  SanityStepListing: StepListing,
  SanityQrCode: QRCode,

  // For Sanity Preview
  articleStacker: ArticleStacker,
  collectionSlider: CollectionSlider,
  tiktokSlider: TiktokSlider,
  productStacker: ProductStacker,
  heroBanner: HeroBanner,
  productFeaturesBlock: ProductFeaturesSlider,
  heroBannerSlider: HeroBannerSlider,
  searchListingBlock: SearchListing,
  masonryimageBlock: MasonryImageBlock,
  productSliderV2: ProductSliderV2,
  articleSlider: ArticleSlider,
  simpleText: SimpleText,
  personListing: PersonListing,
  simpleImageBanner: SimpleImageBanner,
  simpleVideoBanner: SimpleVideoBanner,
  imageVideoBlock: ImageVideoBlock,
  stepListing: StepListing,
  qRCode: QRCode,
};

interface ComponentMapAsyncInterface {
  type: string;
  rest: any;
}

const ComponentsMapAsync: FunctionComponent<ComponentMapAsyncInterface> = ({ type, ...rest }) => {
  const Comp = componentsMap[type];
  return Comp ? <Comp {...rest} fallback={<Fallback />} /> : null;
};

export default ComponentsMapAsync;
